import moment from 'moment'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Offcanvas} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {IoLayersOutline} from 'react-icons/io5'
import {NotificationManager} from 'react-notifications'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Alert from '../components/Alert'
import ChapterItem from '../components/ChapterItem'
import Empty from '../components/Empty'
import Meta from '../components/Meta'
import Button from '../components/UI/Button'
import Loader from '../components/UI/Loader'
import CommentForm from '../components/comment'
import {customImage, customPrice} from '../helpers/all'
import {getChapter} from '../services/chapter'
import {createOrder} from '../services/order'
import {setUser} from '../store/reducers/authSlice'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import Header from '../components/Header'

const Chapter = ({auth}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {chapterId} = useParams()
    const {state} = useLocation()
    const dispatch = useDispatch()
    const options = useSelector((state) => state.settings.options)
    const birthday = useSelector((state) => state.auth.user.birthday)
    const [payAlert, setPayAlert] = useState({text: false, show: false})
    const [chapters, setChapters] = useState({show: false, loading: true, items: []})
    const [chapter, setChapter] = useState({
        loading: true,
        ...(state?.item ?? []),
    })

    const isPublic = chapter?.start ? moment(chapter.start).isBefore() : true
    const [scrollY, setScrollY] = useState(0)

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('touchend', () => setScrollY(0))
        window.addEventListener('click', () => setScrollY(0))
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const onPay = useCallback(() => {
        setPayAlert((prev) => ({...prev, loading: true}))
        createOrder({...chapter, type: 'chapter'})
            .then(({chapter, user}) => {
                user && dispatch(setUser(user))
                chapter && setChapter((prev) => ({...prev, ...chapter, loading: false}))
            })
            .catch((error) => {
                NotificationManager.error(
                    typeof error?.response?.data?.error === 'string'
                        ? error.response.data.error
                        : t('Неизвестная ошибка')
                )
            })
            .finally(() => setPayAlert((prev) => ({...prev, loading: false, show: false})))
    }, [chapter])

    const onLoad = useCallback(() => {
        getChapter(chapterId, auth)
            .then((data) => {
                const index =
                    data?.chapters?.length > 0 && data?.id && data.chapters.findIndex((e) => e.id === data.id)
                const next = data?.chapters?.length > 0 && index != -1 ? data.chapters[index - 1] : false
                const prev = data?.chapters?.length > 0 && index != -1 ? data.chapters[index + 1] : false
                if (data?.comments?.length > 0) {
                    data.comments = data.comments.reverse()
                }
                setChapter((res) => ({
                    ...res,
                    ...data,
                    loading: false,
                    pagination: {
                        next,
                        prev,
                        index,
                    },
                }))
                setChapters({show: false, loading: false, items: data?.chapters})
            })
            .catch(() => setChapter((res) => ({...res, loading: false})))
            .finally(() => window.scrollTo(0, 0))
    }, [chapterId, auth])

    useEffect(() => {
        onLoad()
    }, [chapterId, auth])

    if (chapter?.loading || chapters?.loading) {
        return <Loader full />
    }

    if (!chapter?.id) {
        return (
            <Empty
                text={t('Такой публикации нет')}
                desc={t('Попробуйте зайти позже')}
                button={
                    <Link className="btn-primary" to="/">
                        {t('Перейти в каталог')}
                    </Link>
                }
            />
        )
    }

    if (!auth) {
        return (
            <>
                <Meta
                    title={`${chapter.book.title} - ${chapter?.title ?? t('Глава')} читать онлайн на Gikami`}
                    description={chapter?.desc}
                />
                <Empty
                    text={t('Сначала авторизуйтесь')}
                    desc={t('Доступ только для зарегистрированных пользователей')}
                    button={
                        <Link className="btn-primary" to="/login">
                            {t('Войти в профиль')}
                        </Link>
                    }
                />
            </>
        )
    }

    if (
        !auth &&
        ((!birthday && chapter.book.age > 16) ||
            (moment().diff(moment(birthday), 'year') < chapter.book.age && chapter.book.age > 16))
    ) {
        return (
            <>
                <Meta
                    title={`${chapter.book.title} - ${chapter?.title ?? t('Глава')} читать онлайн на Gikami`}
                    description={chapter?.desc}
                />
                <Empty
                    text={`${t('Контент')} ${chapter.book.age}+`}
                    desc={t(
                        'Данное произведение содержит информацию, не предназначенную для просмотра лицам не достигшими возраста 18 лет'
                    )}
                    button={
                        <Link className="btn-primary" to="/profile/edit">
                            {t('Указать возраст')}
                        </Link>
                    }
                />
            </>
        )
    }

    if (!isPublic) {
        return (
            <>
                <Meta
                    title={`${chapter.book.title} - ${chapter?.title ?? t('Глава')} читать онлайн на Gikami`}
                    description={chapter?.desc}
                />
                <main className="empty d-flex flex-column align-items-center justify-content-center">
                    <div className="mb-4">
                        <img src="/images/announcement.png" width={120} />
                    </div>
                    <div>
                        <p className="text-center h4">{t('Анонс')}</p>
                        <p className="text-center text-muted">
                            {t('Публикация будет добавлена')} {moment(chapter.start).calendar().toLocaleLowerCase()}
                        </p>
                    </div>
                </main>
            </>
        )
    }

    if (chapter?.orders?.length === 0 && chapter?.price > 0) {
        return (
            <>
                <Meta
                    title={`${chapter.book.title} - ${chapter?.title ?? t('Глава')} читать онлайн на Gikami`}
                    description={chapter?.desc}
                />
                <Empty
                    text={t('Публикация не куплена')}
                    desc={t('Доступ к данной публикации ограничен')}
                    button={
                        <>
                            <Link className="btn btn-secondary me-3 btn-sm" to={`/public/${chapter?.bookId}`}>
                                {t('К обложке')}
                            </Link>
                            <Button
                                className="btn-sm"
                                onClick={() =>
                                    setPayAlert((prev) => ({
                                        text: t('Вы подтверждайте покупку?'),
                                        show: !prev.show,
                                    }))
                                }
                            >
                                {t('Купить')} {customPrice({value: chapter?.price ?? 0, color: '#fff', size: 17})}
                            </Button>
                        </>
                    }
                />
                <Alert
                    btnText={t('Купить')}
                    show={payAlert.show}
                    title={t('Покупка')}
                    isLoading={payAlert.loading}
                    desc={t(payAlert.text)}
                    onHide={() => setPayAlert((prev) => ({...prev, loading: false, show: false}))}
                    onClick={() => onPay()}
                />
            </>
        )
    }

    return (
        <>
            <Meta
                title={`${chapter.book.title} - ${chapter?.title ?? t('Глава')} читать онлайн на Gikami`}
                description={chapter?.desc}
            />
            <Header
                scrollY={scrollY}
                headerTitle={
                    <>
                        <img
                            src={customImage({item: chapter.book})}
                            className="header-img me-2"
                            width={25}
                            height={25}
                        />
                        {t('Обложка')}
                    </>
                }
                headerTitleClick={() => navigate('/public/' + (chapter.book.uid ?? chapter.book.id))}
            />
            {!chapter?.content && auth ? (
                <div className="w-100 chat-body custom-scroll py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                    {auth ? t('Сначала авторизуйтесь') : t('Произошла ошибка, попробуйте обновить страницу')}
                </div>
            ) : chapter?.book?.type == 'manhwa' ? (
                <>
                    <h1 className="h4 fw-7 mb-3">{chapter?.title}</h1>
                    <div className="images">
                        {chapter?.medias?.length > 0 &&
                            chapter?.medias.map((e) => (
                                <LazyLoadImage
                                    key={e.id}
                                    // height={e.options.height}
                                    src={customImage({
                                        item: e,
                                        type: 'manhwa',
                                        size: chapter.bookId + '/' + chapter.id,
                                    })}
                                    width="100%"
                                />
                            ))}
                    </div>
                    <div className="hr" />
                    <div className="pb-1 text-muted">
                        {t('Нашли ошибку в тексте?')}{' '}
                        <Link to="/profile/support" className="main-color">
                            {t('Напишите нам')}
                        </Link>
                        .
                    </div>
                </>
            ) : (
                <>
                    <h1 className="h4 fw-7 mb-3">{chapter?.title}</h1>
                    <div
                        id="read"
                        className="read"
                        style={{fontSize: options?.fontSize, color: options?.color}}
                        dangerouslySetInnerHTML={{
                            __html: chapter?.content ?? t('Произошла ошибка, попробуйте обновить страницу'),
                        }}
                    />
                    <div className="hr" />
                    <div className="pb-1 text-muted">
                        {t('Нашли ошибку в тексте?')}{' '}
                        <Link to="/profile/support" className="main-color">
                            {t('Напишите нам')}
                        </Link>
                        .
                    </div>
                </>
            )}
            {chapter.comment && (
                <div className="chapter-comment mt-4">
                    <p className="fw-6 mb-0">Примечание</p>
                    {chapter.comment}
                </div>
            )}
            {auth && (
                <div className="d-flex align-items-center justify-content-between py-4">
                    {chapter?.pagination?.prev && (
                        <Link
                            className="btn btn-secondary w-100"
                            to={`/public/${chapter.book?.uid ?? chapter.book?.id}/read/${
                                chapter.pagination.prev.id
                            }`}
                            state={{item: chapter.pagination.prev, book: chapter.book}}
                        >
                            {chapter?.pagination?.prev?.title ?? t('Назад')}
                        </Link>
                    )}
                    <div>
                        <Button
                            className="btn-secondary mx-2"
                            onClick={() => setChapters((prev) => ({...prev, show: true}))}
                            disabled={chapters?.loading}
                        >
                            <IoLayersOutline size={20} />
                        </Button>
                    </div>
                    {chapter?.pagination?.next && (
                        <Link
                            className="btn btn-primary w-100"
                            to={`/public/${chapter.book?.uid ?? chapter.book?.id}/read/${
                                chapter.pagination.next.id
                            }`}
                            state={{item: chapter.pagination.next, book: chapter.book}}
                        >
                            {chapter?.pagination?.next?.title ?? t('Далее')}
                        </Link>
                    )}
                </div>
            )}

            <div className="comment-last-box mb-3">
                <h5 className="mb-3 mt-2 fw-7">{t('Последние комментарии')}</h5>
                <CommentForm
                    like={false}
                    menu={false}
                    input={false}
                    emptyText={t('Нет комментариев')}
                    data={chapter?.comments?.length > 0 ? chapter.comments.slice(-3).reverse() : []}
                />
                <Link
                    className="btn btn-primary w-100 ms-2"
                    to={`/public/${chapter.book?.uid ?? chapter.book?.id}/read/${chapter.id}/comment`}
                    state={{items: chapter?.comments}}
                >
                    {t(chapter?.comments?.length > 0 ? 'Перейти к комментариям' : 'Добавить комментарий')}
                </Link>
            </div>

            <Offcanvas
                restoreFocus={false}
                show={chapters.show}
                onHide={() => setChapters((prev) => ({...prev, show: false}))}
                placement="end"
                name="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fw-bold">{t('Главы')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body a className="custom-scroll">
                    {chapters?.items?.length > 0 &&
                        chapters.items.map((e) => <ChapterItem key={e.id} item={e} book={chapter.book} onClick={() => setChapters((prev) => ({...prev, show: false}))} />)}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Chapter
