import {createApi} from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import {ClientJS} from 'clientjs'
import {BASE_URL, apiRoutes} from '../config/api'
import socket from '../config/socket'
import {store} from '../store'
import {setAuth, setToken, setUser} from '../store/reducers/authSlice'
import {refreshAuth} from './auth'
import {useSelector} from 'react-redux'
const client = new ClientJS()

const DEVICE = JSON.stringify({
    brand: client.getBrowser(),
    osName: client.getOS(),
    osVersion: client.getOSVersion(),
    language: client.getLanguage() ?? 'ru-RU',
})

const $authApi = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
})

$authApi.interceptors.request.use(
    async (config) => {
        const state = store.getState()
        config.headers.ip = state?.settings?.ip ?? '0.0.0.0'
        const token = state?.auth?.token ?? false

        if (token) {
            config.headers.authorization = `Access ${token}`
        }

        config.headers.device = DEVICE
        return config
    },
    (error) => Promise.reject(error)
)

$authApi.interceptors.response.use(
    (config) => {
        return config
    },
    async (error) => {
        const {config, response} = error
        const originalRequest = config
        if (response?.status === 401 && originalRequest && !originalRequest._isRetry) {
            originalRequest._isRetry = true
            return store.dispatch(refreshAuth()).then(() => $authApi(originalRequest))
        } else if (response?.status === 403) {
            store.dispatch(setUser(false))
            store.dispatch(setAuth(false))
            store.dispatch(setToken(false))

            return socket.disconnect()
        }
        return Promise.reject(error)
    }
)

export const cache = createApi({
    reducerPath: 'cache',
    baseQuery: $authApi,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    keepUnusedDataFor: 500,
    endpoints: (build) => ({
        getCatalog: build.query({
            query: (params) => {
                return {
                    url: params?.auth ? apiRoutes.CATEGORIES : apiRoutes.CATEGORIES_NO_AUTH,
                    params,
                }
            },
        }),
        getNotifications: build.query({
            query: () => apiRoutes.NOTIFICATIONS,
        }),
        getTransactions: build.query({
            query: () => apiRoutes.TRANSACTIONS,
        }),
        getOrders: build.query({
            query: () => apiRoutes.ORDERS,
        }),
        getSessions: build.query({
            query: () => apiRoutes.SESSIONS,
        }),
    }),
})

export const {
    useGetCatalogQuery,
    useGetNotificationsQuery,
    useGetTransactionsQuery,
    useGetOrdersQuery,
    useGetSessionsQuery
} = cache
