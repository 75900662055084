import React, {useLayoutEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import BookItem from '../../components/BookItem'
import Meta from '../../components/Meta'
import Loader from '../../components/UI/Loader'
import {getPublic} from '../../services/user'

const Public = () => {
    const {t} = useTranslation()

    const [data, setData] = useState({
        loading: true,
        items: [],
    })

    useLayoutEffect(() => {
        getPublic().then((res) => setData((prev) => ({...prev, ...res, loading: false})))
    }, [])

    if (data?.loading) {
        return <Loader full />
    }

    return (
        <>
            <Meta title={t("Мои публикации")} />
            <h3 className="mb-3">{t('Мои публикации')}</h3>
            {data.items?.length > 0 && (
                <Row>
                    {data.items.map((e) => (
                        <Col key={e.id} xxl={3} xl={3} lg={3} md={4} sm={6} xs={6}>
                            <BookItem item={e} />
                        </Col>
                    ))}
                </Row>
            )}
        </>
    )
}

export default Public
