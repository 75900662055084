import React, {memo} from 'react'
import {Container} from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {useTranslation} from 'react-i18next'
import {NavLink} from 'react-router-dom'

const Footer = memo(() => {
    const {t} = useTranslation()
    return (
        <footer className="d-none d-lg-block">
            <div className="col-xxl-7 col-xl-9 col-lg-10 col-md-11 col-sm-12 m-auto d-flex h-100 align-items-center">
                <Container>
                    <Row md={3} className="w-100 d-flex">
                        <Col>
                            <h5 className="mb-2">Gikami</h5>
                            <div className="d-flex flex-wrap">
                                <a
                                    href="https://play.google.com/store/apps/details?id=ru.gikami.app"
                                    target="_blank"
                                    className="d-flex align-items-center me-2 app-link mb-2 line-height-100"
                                >
                                    <div className="me-2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M15.3099 12.7188L14.0282 11.386L9.78055 15.9436L15.3099 12.7188Z M16.958 11.7576L17.0071 11.729C18.3303 10.9572 18.3303 9.04538 17.0071 8.27366L16.9564 8.2441L15.3199 10L16.958 11.7576Z M15.3082 7.28288L9.78589 4.06215L14.0282 8.61402L15.3082 7.28288Z M4.04121 1.00037L12.6954 10L4.03865 19.0023C2.98068 19.0214 2 18.1809 2 16.9996V3.00301C2 1.82076 2.98226 0.979819 4.04121 1.00037Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </div>
                                    <div>
                                        <p className="mb-0 fs-07 light-gray">{t('Доступно в')}</p>
                                        <p className="mb-0 fs-08 fw-6">Google Play</p>
                                    </div>
                                </a>
                                {/* <a className="d-flex align-items-center app-link mb-3">
                                    <div>
                                        <img src="/images/social/app-store.svg" className="me-2" />
                                    </div>
                                    <div>
                                        <p className="fs-07 light-gray">Доступно в</p>
                                        <p className="fs-08">App Store</p>
                                    </div>
                                </a> */}
                            </div>

                            <p className="light-gray fs-07 mb-0">
                                {t(
                                    'Сайт может содержать материалы, не предназначенные для просмотра лицами, не достигшими 18 лет!'
                                )}
                            </p>
                        </Col>
                        <Col>
                            <h5 className="mb-2">{t('Информация')}</h5>
                            <nav>
                                <ul className="list-unstyled mb-0 fs-09">
                                    <li>
                                        <NavLink to="/about" className="font-faded">
                                            {t('О нас')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/term-user" className="font-faded">
                                            {t('Пользовательское соглашение')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/policy" className="font-faded">
                                            {t('Политика о конфиденциальности')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/term-author" className="font-faded">
                                            {t('Правила публикации')}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/offer" className="font-faded">
                                            {t('Публичная оферта')}
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                        <Col>
                            <h5 className="mb-2">{t('Связаться с нами')}</h5>
                            <nav>
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex mb-2">
                                        <a href="https://vk.com/gikami" className="me-2" target="_blank">
                                            <img
                                                src="/images/social/vk.svg"
                                                height={35}
                                                title="Сообщество ВКонтакте"
                                            />
                                        </a>
                                        <a href="https://t.me/gikami_ru" className="me-2" target="_blank">
                                            <img
                                                src="/images/social/telegram.svg"
                                                height={35}
                                                title="Канал в Telegram"
                                            />
                                        </a>
                                        {/* <a
                                            href="https://www.youtube.com/channel/UC1oieF--6zqhuTSlA933sDQ"
                                            className="me-3"
                                            target="_blank"
                                        >
                                            <img
                                                src="/images/social/youtube.svg"
                                                height={35}
                                                title="Канал на YouTube"
                                            />
                                        </a> */}
                                        {/* <a href="https://instagram.com/gikami_ru" target="_blank">
                                            <img
                                                src="/images/social/instagram.svg"
                                                height={35}
                                                title="Запрещенная в России социальная сеть"
                                            />
                                        </a> */}
                                    </li>
                                    <li>
                                        <a to="mailer:info@gik.pw" className="font-faded">
                                            info@gik.pw
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
})

export default Footer
